import(/* webpackMode: "eager" */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/components/AnimatedTitle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/components/CirclesSlider/CirclesSlider.tsx");
;
import(/* webpackMode: "eager" */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/components/Img.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/components/RevealingPoints/RevealingPoints.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/components/StatisticTiles/StatisticTiles.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/components/TeamSlider/TeamSlider.tsx");
;
import(/* webpackMode: "eager" */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Blog/Article/Article.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Cases/Case/Challenges/Challenges.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Cases/Case/DefaultScreenBlock/DefaultScreenBlock.tsx");
;
import(/* webpackMode: "eager" */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Cases/Case/DetailsBlock/DetailsBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Cases/Case/DevelopmentComponentsBlock/DevelopmentComponentsBlock.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Cases/Case/FeatureBlock/FeatureBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Cases/Case/ProjectDetails/ProjectDetails.tsx");
;
import(/* webpackMode: "eager" */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Cases/Case/Solutions/Solutions.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Cases/Case/StatsBlock/StatsBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Company/CompanyGrowth.tsx");
;
import(/* webpackMode: "eager" */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Company/CompanyMission.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Company/CompanyPhilosophy.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Company/CompanyTeam.tsx");
;
import(/* webpackMode: "eager" */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Education/Mentors.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Home/About/About.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Home/CasesBlock/CasesBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Home/CasesBlock/DynamicCasesBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Home/Clients/Clients.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Home/Consultation/Consultation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Home/Expertises/Expertises.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Home/Hero/Hero.tsx");
;
import(/* webpackMode: "eager" */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Home/Home.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Home/HomepageSidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Home/Reviews/Reviews.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Home/TechStack/TechStack.tsx");
;
import(/* webpackMode: "eager" */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Info/Info.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Info/InfoCard/InfoCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Services/Benefits/Benefits.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Services/HowWeWork.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Services/Posts/ServicePosts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Services/ServicesSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Services/SocialCards/SocialCardItem.tsx");
;
import(/* webpackMode: "eager" */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Services/SocialCards/SocialCards.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Services/Subservices/SubserviceListItem.tsx");
;
import(/* webpackMode: "eager" */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Services/Subservices/SubservicesList.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Subservice/Microservices.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/page-components/Subservice/Team.tsx");
